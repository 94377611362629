<template>
  <section>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <TitlePlus :title="pageTitle" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row">
          <SummaryCard
            :title="$t('components.reportManagement.summary.totalReports')"
            :value="indexMetaData.summary.total"
            variant="gray"
          />

          <SummaryCard
            :title="$t('components.reportManagement.summary.verified')"
            :value="indexMetaData.summary.verified"
            variant="gray"
          />

          <SummaryCard
            :title="$t('components.reportManagement.summary.pending')"
            :value="indexMetaData.summary.pending"
            variant="gray"
          />
        </div>
      </div>
      <template v-if="$acl.canView('reports')">
        <div class="full-mode">
          <FSTable
            :qso="qso"
            :fst-id="fstId"
            :headers="tableHeaders"
            :endpoint="endpoint"
            :exportType="exportKey.REPORT"
            :exportFromURL="true"
            :isAISyncEnabled="true"
            @meta="(e) => (indexMetaData = e)"
          >
            <template #topLeft="{ slotWidth }">
              <keep-alive>
                <FSTableFilter
                  :fst-id="fstId"
                  :slot-width="slotWidth"
                  :options="getFilteredItems"
                />
              </keep-alive>
            </template>

            <template #default="{ data }">
              <FSTableRow
                v-for="(item, itemIndex) in data"
                :key="itemIndex"
                text-fallback-always
              >
                <FSTableRowItem
                  :text="item.id"
                  :to="{ name: 'ReportDetails', params: { id: item.id } }"
                  :truncate="-5"
                />

                <FSTableRowItem
                  :text="item.user.full_name"
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.user.id },
                  }"
                />

                <FSTableRowItem
                  :text="item.bike.qr_code"
                  :to="{
                    name: 'ViewVehicleProfile',
                    params: { id: item.bike.id },
                  }"
                />

                <FSTableRowItem :text="getUTCAwareTime(item.report_date)" />

                <FSTableRowItem>
                  {{ item.report_category }}

                  <p
                    class="fonts-weight-100 text-xs"
                    v-if="item.vehicle_parts.length > 0"
                  >
                    ({{ capitalizeWords(item.vehicle_parts).join(', ') }})
                  </p>
                </FSTableRowItem>

                <FSTableRowItem :text="item.report_moment" />
                <FSTableRowItem class="word-wrap" :text="item.subject" />

                <FSTableRowItem>
                  <MoreActions
                    :primaryKey="itemIndex"
                    :details="item"
                    @change="handleRefresh"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>
          </FSTable>
        </div>
        <div class="responsive-mode">
          <FSTable
            :qso="qso"
            :fst-id="fstId"
            :headers="resTableHeaders"
            :endpoint="endpoint"
            :exportType="exportKey.REPORT"
            :exportFromURL="true"
            :isAISyncEnabled="true"
            @meta="(e) => (indexMetaData = e)"
          >
            <template #topLeft="{ slotWidth }">
              <keep-alive>
                <FSTableFilter
                  :fst-id="fstId"
                  :slot-width="slotWidth"
                  :options="getFilteredItems"
                />
              </keep-alive>
            </template>

            <template #default="{ data }">
              <template v-for="(item, itemIndex) in data">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  text-fallback-always
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="item.id"
                    :to="{ name: 'ReportDetails', params: { id: item.id } }"
                    :truncate="-5"
                  />

                  <FSTableRowItem
                    :text="item.bike.qr_code"
                    :to="{
                      name: 'ViewVehicleProfile',
                      params: { id: item.bike.id },
                    }"
                  />

                  <FSTableRowItem>
                    <MoreActions
                      :primaryKey="itemIndex"
                      :details="item"
                      @change="handleRefresh"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.reportManagement.table.columns.reportedBy'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        <router-link
                          :to="{
                            name: 'ViewRiderUserProfile',
                            params: { id: item.user.id },
                          }"
                          :class="`text-blue-600`"
                          target="_blank"
                        >
                          {{ item.user.full_name }}
                        </router-link>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.reportManagement.table.columns.date')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ getUTCAwareTime(item.report_date) || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.reportManagement.table.columns.reportCategory'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.report_category }}

                        <p
                          class="fonts-weight-100 text-xs"
                          v-if="item.vehicle_parts.length > 0"
                        >
                          ({{ capitalizeWords(item.vehicle_parts).join(', ') }})
                        </p>
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t('components.reportManagement.table.columns.phase')
                        }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.report_moment || `--` }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{
                          $t(
                            'components.reportManagement.table.columns.subject'
                          )
                        }}
                      </div>
                      <div class="col-span-5 right-text word-wrap">
                        {{ item.subject || `--` }}
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </FSTable>
        </div>
      </template>

      <div v-else class="py-5 font-bold text-center text-gray-600">
        You don't have permission to see data. Please Ask your admin to give you
        permission for this screen.
      </div>
    </content-section>
  </section>
</template>

<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { getUTCAwareTime } from '@/utils'
import { exportKey } from '@/utils/export-data/helper'

export default {
  name: 'ReportIndex',

  props: {
    pageTitle: {
      type: String,
      default: 'Reports',
    },
    fstId: {
      type: String,
      default: 'reports',
    },
    endpoint: {
      type: String,
      default: useEndpoints.report.index(),
    },
    qso: {
      type: Object,
      default: () => ({ append: '', prepend: '?' }),
    },
    filterItems: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ContentSection: () => import('@/components/layout/ContentSection'),
    SummaryCard: () => import('@/components/cards/SummaryCard'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    MoreActions: () => import('@/composites/report/shared/MoreActions.vue'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
  },

  data() {
    return {
      exportKey,
      indexMetaData: {
        summary: {
          total: 0,
          verified: 0,
          pending: 0,
        },
      },

      tableHeaders: [
        {
          text: this.$t('components.reportManagement.table.columns.reportID'),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t('components.reportManagement.table.columns.reportedBy'),
          width: '10%',
          sort: 'user',
        },
        {
          text: this.$t('components.reportManagement.table.columns.vehicleQR'),
          width: '12%',
          sort: 'bike',
        },
        {
          text: this.$t('components.reportManagement.table.columns.date'),
          width: '17%',
          sort: 'pick_up_time',
        },
        {
          text: this.$t(
            'components.reportManagement.table.columns.reportCategory'
          ),
          width: '13%',
          sort: 'pick_up_location',
        },
        {
          text: this.$t('components.reportManagement.table.columns.phase'),
          width: '10%',
          sort: 'report_moment',
        },
        {
          text: this.$t('components.reportManagement.table.columns.subject'),
          width: '18%',
          sort: 'drop_off_location',
        },
        {
          text: this.$t('components.reportManagement.table.columns.status'),
          width: '10%',
          sort: 'drop_off_time',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t('components.reportManagement.table.columns.reportID'),
          width: '20%',
          sort: 'id',
        },
        {
          text: this.$t('components.reportManagement.table.columns.vehicleQR'),
          width: '40%',
          sort: 'bike',
        },
        {
          text: this.$t('components.reportManagement.table.columns.status'),
          width: '30%',
          sort: 'drop_off_time',
        },
      ],
      opened: [],

      reportCategoryChoice: {
        B: 'Broken Bike',
        I: 'Illegal Parking',
        U: 'Unauthorized lock',
        P: 'Payment',
        O: 'Other',
      },
      reportStatusChoice: {
        A: 'Pending',
        U: 'Unverified',
        R: 'Verified',
      },

      invoiceModalDetails: {},

      // generated by generateFilterOptions()
      filterOptions: [],
    }
  },

  computed: {
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },
  },

  async created() {
    await this.generateFilterOptions()
  },

  methods: {
    getUTCAwareTime,

    onApplyFilterDateRange,
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    capitalizeWords(arr) {
      return arr.map((element) => {
        return (
          element.charAt(0).toUpperCase() + element.substring(1).toLowerCase()
        )
      })
    },

    async generateFilterOptions() {
      let filters = [
        {
          key: 'report_category',
          type: 'checkbox',
          input: [
            { text: 'Damaged Vehicle', value: 'DAMAGED_VEHICLE' },
            { text: 'Need Maintenance', value: 'NEED_MAINTENANCE' },
            { text: 'Wrongly Parked', value: 'WRONGLY_PARKED' },
            { text: 'Missing', value: 'MISSING' },
            { text: 'Out of service area', value: 'OUT_OF_SERVICE_AREA' },
            { text: 'Restricted area', value: 'RESTRICTED_AREA' },
            { text: 'Other', value: 'OTHER' },
          ],
          title: 'Category',
        },

        {
          key: 'report_status',
          type: 'checkbox',
          input: [
            { text: 'Attention Required', value: 'ATTENTION_REQUIRED' },
            { text: 'In Review', value: 'UNDER_PROGRESS' },
            { text: 'Resolved', value: 'RESOLVED' },
          ],
          title: 'Status',
        },
        {
          key: 'report_type',
          type: 'checkbox',
          input: [
            { text: 'Rental', value: 'rental' },
            { text: 'Trip', value: 'trip' },
          ],
          title: 'Type',
        },
        {
          key: 'report_moment',
          type: 'checkbox',
          input: [
            { text: 'Before Trip', value: 'B' },
            { text: 'On Rent', value: 'O' },
            { text: 'On Feedback', value: 'F' },
            { text: 'After Trip', value: 'A' },
          ],
          title: 'Phase',
        },
      ]
      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },

    getReportStatusBadge(category) {
      let categories = {
        Pending: 'orange',
        Verified: 'green',
        Unverified: 'red',
      }
      return category in categories ? categories[category] : '--'
    },

    getReportCategoryChoice(cat) {
      return cat in this.reportCategoryChoice
        ? this.reportCategoryChoice[cat]
        : '--'
    },
    handleRefresh() {
      this.$store.dispatch('fsTable/fetchData').then((res) => {
        this.indexMetaData.summary = res.data.meta.summary
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  /* font-family: Roboto; */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
  //text-align: right;
}
.word-wrap {
  max-width: 200px;
  word-wrap: break-word;
}
</style>
