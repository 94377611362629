var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('div',[_c('TitlePlus',{attrs:{"title":_vm.pageTitle,"hide-plus":true}})],1),_c('div',{staticClass:"flex md:justify-end"},[_c('date-range-picker',{on:{"apply-date":_vm.onApplyFilterDateRange,"cancel-date":_vm.onApplyFilterDateRange}})],1)]),_c('div',{staticClass:"flex flex-col gap-4 mt-4 mb-10 md:items-center md:flex-row"},[_c('SummaryCard',{attrs:{"title":_vm.$t('components.reportManagement.summary.totalReports'),"value":_vm.indexMetaData.summary.total,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.reportManagement.summary.verified'),"value":_vm.indexMetaData.summary.verified,"variant":"gray"}}),_c('SummaryCard',{attrs:{"title":_vm.$t('components.reportManagement.summary.pending'),"value":_vm.indexMetaData.summary.pending,"variant":"gray"}})],1)]),(_vm.$acl.canView('reports'))?[_c('div',{staticClass:"full-mode"},[_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.tableHeaders,"endpoint":_vm.endpoint,"exportType":_vm.exportKey.REPORT,"exportFromURL":true,"isAISyncEnabled":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.getFilteredItems}})],1)]}},{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',{attrs:{"text":item.id,"to":{ name: 'ReportDetails', params: { id: item.id } },"truncate":-5}}),_c('FSTableRowItem',{attrs:{"text":item.user.full_name,"to":{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.user.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":item.bike.qr_code,"to":{
                  name: 'ViewVehicleProfile',
                  params: { id: item.bike.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":_vm.getUTCAwareTime(item.report_date)}}),_c('FSTableRowItem',[_vm._v(" "+_vm._s(item.report_category)+" "),(item.vehicle_parts.length > 0)?_c('p',{staticClass:"fonts-weight-100 text-xs"},[_vm._v(" ("+_vm._s(_vm.capitalizeWords(item.vehicle_parts).join(', '))+") ")]):_vm._e()]),_c('FSTableRowItem',{attrs:{"text":item.report_moment}}),_c('FSTableRowItem',{staticClass:"word-wrap",attrs:{"text":item.subject}}),_c('FSTableRowItem',[_c('MoreActions',{attrs:{"primaryKey":itemIndex,"details":item},on:{"change":_vm.handleRefresh}})],1)],1)})}}],null,false,3291032162)})],1),_c('div',{staticClass:"responsive-mode"},[_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.resTableHeaders,"endpoint":_vm.endpoint,"exportType":_vm.exportKey.REPORT,"exportFromURL":true,"isAISyncEnabled":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
                var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.getFilteredItems}})],1)]}},{key:"default",fn:function(ref){
                var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"text-fallback-always":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":item.id,"to":{ name: 'ReportDetails', params: { id: item.id } },"truncate":-5}}),_c('FSTableRowItem',{attrs:{"text":item.bike.qr_code,"to":{
                    name: 'ViewVehicleProfile',
                    params: { id: item.bike.id },
                  }}}),_c('FSTableRowItem',[_c('MoreActions',{attrs:{"primaryKey":itemIndex,"details":item},on:{"change":_vm.handleRefresh}})],1)],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.reportManagement.table.columns.reportedBy' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('router-link',{class:"text-blue-600",attrs:{"to":{
                          name: 'ViewRiderUserProfile',
                          params: { id: item.user.id },
                        },"target":"_blank"}},[_vm._v(" "+_vm._s(item.user.full_name)+" ")])],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.reportManagement.table.columns.date'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getUTCAwareTime(item.report_date) || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.reportManagement.table.columns.reportCategory' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.report_category)+" "),(item.vehicle_parts.length > 0)?_c('p',{staticClass:"fonts-weight-100 text-xs"},[_vm._v(" ("+_vm._s(_vm.capitalizeWords(item.vehicle_parts).join(', '))+") ")]):_vm._e()])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t('components.reportManagement.table.columns.phase'))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.report_moment || "--")+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.reportManagement.table.columns.subject' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text word-wrap"},[_vm._v(" "+_vm._s(item.subject || "--")+" ")])])])]):_vm._e()]})]}}],null,false,1030490345)})],1)]:_c('div',{staticClass:"py-5 font-bold text-center text-gray-600"},[_vm._v(" You don't have permission to see data. Please Ask your admin to give you permission for this screen. ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }